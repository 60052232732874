<template>
  <div class="i-tpos">
    <div class="container">
      <ProjectCard :project="$project" />
    </div>
  </div>
</template>

<script>
import ProjectCard from "./../../components/ProjectCard.vue";

export default {
  components: { ProjectCard },
  computed: {
    $project() {
      return {
        landing_image: "https://tecpyme.mx/data/assets/tpos.png",
        name: "TecPyme POS",
        label: "POS & BUSSINESS ADMINISTRATION SYSTEM",
        description:
          "PHP system developed since 2017, with the purpose to be innovative and help customers to met the billing requirements from Mexico.",
        link: [
          {
            title: "Product landing website",
            href: "https://tecpyme.mx",
          },
        ],
        tech: [
          {
            title: "BACKEND",
            stack: [
              { name: "PHP", variant: "white" },
              { name: "Phinx", variant: "white" },
              { name: "MySQL", variant: "white" },
            ],
          },
          {
            title: "FRONTEND",
            stack: [
              { name: "Vue.js", variant: "i-primary" },
              { name: "Electron.js", variant: "white" },
              { name: "Bootstrap CSS", variant: "white" },
            ],
          },
          {
            title: "EXTRAS",
            small: true,
            stack: [
              { name: "SAT CFDI 3.3/4.0", variant: "white" },
              { name: "PHP FPDF", variant: "white" },
              { name: "Mailer", variant: "white" },
            ],
          },
        ],
        info: [
          {
            title: "THE PROBLEM",
            description: [
              {
                type: "text",
                value:
                  "Customers at TecPyme were having issues with billing, back in 2017. This was because the CFDI 3.3 scheme was going to take in place and many systems were not prepared to work. We started the development of this system, with me as a main developer. The purpose of this Software is to work as a Point of Sale (POS) system principally, but also allows bussiness administration.",
              },
              {
                type: "text",
                value:
                  "This system is shipped under the PaaS (Product as a Service) scheme, where each business purchases a license with no vigency, to use this Software.",
              },
              {
                type: "text",
                value:
                  "Is been more than +4 years in development. As for now, many modules have been introduced and deployed. Clients, Providers, Products, Invoices, POS tickets, Business expenses, Reports and Inventory.",
              },
            ],
          },
          {
            title: "ATTEMPTING THE IMPOSSIBLE",
            description: [
              {
                type: "text",
                value:
                  "We had a big trouble back in 2017. We were aware that almost all POS systems on the market depends from the Internet or a local server to be running to work. We knew that, and we also knew that some bussineses would get drops on the sales if server suddenly stops working.",
              },
              {
                type: "text",
                value:
                  "Just put yourself in the situation. You're the customer and you want some groceries from the market, but somehow the server stops working. If this happens on a real situation, is likely you would not be able to get your invoice/ticket on the POS.",
              },
              {
                type: "text",
                value:
                  "Sadly, almost all bussineses suffers of this risks and have some plans to prevent them, but we wanted to solve this issue from the root.",
              },
              {
                type: "image",
                value: "https://tecpyme.mx/data/assets/ivypos.png",
                alt: "Electron.js POS client, with SQLite.",
              },
            ],
          },
          {
            title: "SOLUTION",
            description: [
              {
                type: "text",
                value: `This system, on its core, is Vanilla PHP-based. Everything was coded from scratch, except from some frameworks used on the Frontend as Vue.js, Bootstrap CSS and so on. We used PHP because is simple to use, quick to learn and works cross-platform. MySQL was the best bet in our analysis, because it is "free" at some point and reliable.`,
              },
              {
                type: "text",
                value: `A stand-alone client was coded besides, which is basically a POS client. This client has capabilities to print on thermal printers by itself. It uses SQLite as a DB, because it is portable and simple to use. When the handshake is completely done, it basically clones the products and clients from server, and checksums it. This data is stored on SQLite. With this approach, if server stops working the POS client can keep working on its own. We use prediction algorithms to generate the invoices, and a sync system to keep server and client synced, without any sort of conflicts. What would happen if X product changes on server? We implemented an event-based system, with the purpose to notify those updates OTA.`,
              },
            ],
          },
        ],
      };
    },
  },
};
</script>

<style>
</style>