<template>
  <div class="i-tpos">
    <div class="container">
      <ProjectCard :project="$project" />
    </div>
  </div>
</template>

<script>
import ProjectCard from "./../../components/ProjectCard.vue";

export default {
  components: { ProjectCard },
  computed: {
    $project() {
      return {
        landing_image: "/images/tcloud.png",
        windows: true,
        name: "TecPyme Cloud",
        label: "REAL-TIME POS & BUSINESS MONITORING SYSTEM",
        description:
          "Node.js system developed since 2020, with the purpose to have business status and sales on real-time, accessible from PCs and phones, from anywhere. This product is intended only for TecPyme POS customers.",
        link: [
          {
            title: "Product landing website",
            href: "https://tecpyme.mx",
          },
        ],
        tech: [
          {
            title: "BACKEND",
            stack: [
              { name: "Node.js", variant: "white" },
              { name: "Sequelize", variant: "white" },
              { name: "JWT", variant: "white" },
              { name: "MySQL", variant: "white" },
              { name: "PM2", variant: "white" },
              { name: "AWS/DigitalOcean/Vultr", variant: "white" },
              { name: "CloudFlare", variant: "white" },
              { name: "Protobuf", variant: "white" },
              { name: "Linux", variant: "white" },
            ],
          },
          {
            title: "FRONTEND",
            stack: [
              { name: "Vue.js", variant: "i-primary" },
              { name: "Bootstrap CSS", variant: "white" },
              { name: "WebSockets", variant: "white" },
            ],
          },
          {
            title: "EXTRAS",
            small: true,
            stack: [
              { name: "Webhooks", variant: "white" },
              { name: "Mailer", variant: "white" },
              { name: "AES-256-CBC", variant: "white" },
              { name: "Docker", variant: "white" },
              { name: "Vagrant", variant: "white" },
            ],
          },
        ],
        info: [
          {
            title: "THE PROBLEM",
            description: [
              {
                type: "text",
                value:
                  "TecPyme POS had some customers that could have distinct branches across the city, or through states! Some of them just wanted to see their sales and graphs on real-time, from their phones. With the POS solution is possible, but not easy. Customers had to either purchase a domain or connect directly through public branch servers IPs (where they live on each branch). This definitely was not easy and simple, from the user perspective. This is why a cloud solution was built, to gather those issues. With me, as the main developer.",
              },
              {
                type: "text",
                value:
                  "This system is shipped under the PaaS (Product as a Service) scheme, where each business purchases a license with a monthly-basis invoice, to keep using this cloud solution.",
              },
              {
                type: "text",
                value:
                  "The product is pretty young, it has been one year ago since it was developed and is used daily by TecPyme customers. They can have bussiness expenses control, generate reports remotely from each system branch, watch sales graphs in real-time, allow cashiers to perform actions and keep all connected POS systems synced from the main branch.",
              },
            ],
          },
          {
            title: "ATTEMPTING THE IMPOSSIBLE",
            description: [
              {
                type: "text",
                value:
                  "We had a big struggle on the analysis here, as well. The system had to be cloud-based, so it can be accesible anywhere, real-time and also keep all system branches synced (which means, all products must have the same price from the main branch, customers must be equal, etc). I built a sync system, based on the client POS solution with the same approach: clone and checksum, put an event-system which notifies whenever a change happens. This approach, along with MySQL transactions did make this possible.",
              },
              {
                type: "image",
                value: "/images/cashier-performance.png",
                alt: "Cashier performance, main graphs.",
                rounded: true,
              },
              {
                type: "image",
                value: "/images/cashier-performance-2.png",
                alt: "Cashier performance, by concepts graphs.",
                rounded: true,
              },
            ],
          },
          {
            title: "SOLUTION",
            description: [
              {
                type: "text",
                value: `This system, on its core, is Vanilla Node.js based. No transpiler, babel, etc. Just raw JS code running on Node.js. Everything was coded from scratch, except from some frameworks used on the Frontend as Vue.js, Bootstrap CSS and so on. I used Node.js because this cloud solution shall be designed to be used in real-time and websockets was a must-have requirement. MySQL was the best bet in our analysis, because it is "free" at some point and reliable. For websockets, I implemented socket.io.`,
              },
              {
                type: "text",
                value:
                  "I added strong security to this system because is an important subject to me. All connected systems data is encrypted with a master password, using AES-256-CBC. This password, is given by the bussiness owner to the system and then the server stores it on RAM. This approach was taken to prevent leakage, if something bad occurs.",
              },
              {
                type: "text",
                value:
                  "It is heavily optimized to run on mobile devices, that's why Protobuffers was my best bet on this case. A symmetric and simple encryption was added in place, which encrypts and decrypts raw binary packets from server to client and backwards.",
              },
              {
                type: "text",
                value: `This solution runs on cloud instances. At the start, we used AWS as a cloud provider but we later switched to Digital Ocean and finally to Vultr, because of server costs, locations, etc. I do the deployments personally and I push the updates by my own, everything through SSH.`,
              },
            ],
          },
        ],
      };
    },
  },
};
</script>

<style>
</style>