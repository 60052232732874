<template>
  <a class="i-social-media-icon" :href="href" target="_blank">
    <div
      class="ism-icon"
      :style="{ 'background-image': `url(/icons/${icon})` }"
    />
  </a>
</template>

<script>
export default {
  name: "SocialMediaBtn",
  props: {
    href: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.i-social-media-icon {
  outline: none !important;
  text-decoration: none !important;
}

.ism-icon:hover,
.ism-icon:active {
  transform: scale(1.2) !important;
}

.ism-icon {
  transition: transform 200ms;
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}
</style>