<template>
  <a :href="src" :alt="alt" target="_blank">
    <img :src="src" :alt="alt" :style="imgStyle" :class="$class" />
  </a>
</template>

<script>
export default {
  name: "IImage",
  props: {
    src: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    imgStyle: {
      type: String,
      default: "",
    },
  },
  computed: {
    $class() {
      const classes = [];
      if (this.rounded === true) {
        classes.push("rounded");
        classes.push("shadow");
      }
      return classes;
    },
  },
};
</script>

<style>
</style>