<template>
  <div
    id="app"
    class="mt-3"
    style="display: none"
    :class="{ 'd-block': isReady }"
  >
    <Navbar class="mb-5" />
    <transition :name="transitionName">
      <router-view class="child-view pt-3" v-if="showRouter"></router-view>
    </transition>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
export default {
  name: "App",
  components: {
    Navbar,
  },
  data() {
    return {
      isReady: false,
      showRouter: false,
      transitionName: "slide-left",
    };
  },
  created() {
    document.title = "Portfolio | Bryan Iribe";
    this.isReady = true;
    setTimeout(() => {
      this.showRouter = true;
    }, 10);
  },
};
</script>

<style>
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css";
@import "https://fonts.googleapis.com/css2?family=Dongle:wght@300&display=swap";

:root {
  --i-primary: #00b894;
  --i-gray: #ececec;
}

.text-i-primary {
  color: var(--i-primary) !important;
}

.bg-i-primary {
  background-color: var(--i-primary) !important;
}
p {
  line-height: 1.2rem;
}

p,
span,
small,
label {
  text-align: justify;
}

html {
}

html,
body,
#app,
.child-view {
  width: 100%;
  font-family: "Dongle", sans-serif !important;
  font-size: 1.3rem;
  line-height: 1.5rem;
}

.child-view {
  padding-bottom: 3rem;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}
body {
  background-color: white;
}

.rounded-tp {
  border-radius: 0.5rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.child-view {
  position: absolute;
  transition: all 200ms cubic-bezier(0.55, 0, 0.1, 1);
}
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}
</style>
