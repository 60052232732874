<template>
  <div class="i-project-card">
    <div class="row">
      <div class="col-lg-7 mb-5">
        <IImage
          :src="project.landing_image"
          :rounded="project.windows === true"
          img-style="max-width: 100%"
        />
      </div>
      <div class="col-lg-5 mb-5">
        <h1 class="d-block mb-2">{{ project.name }}</h1>
        <p class="text-i-primary font-weight-bold">
          {{ project.label }}
        </p>
        <p class="mb-1">{{ project.description }}</p>
        <a
          :href="link.href"
          target="_blank"
          v-for="link in project.link"
          :key="link.href"
          >{{ link.title }}</a
        >
        <p class="text-muted" v-if="!project.link || project.link.length <= 0">
          PRODUCT LANDING WEBSITE NOT AVAILABLE FOR PUBLIC. PLEASE READ BELOW.
        </p>
        <small
          class="d-block text-small"
          style="line-height: 0.8rem"
          v-if="project.advice && project.advice.length > 0"
          >{{ project.advice }}</small
        >
        <p></p>
        <div
          class="d-block w-100 i-tech-stack"
          v-for="tech in project.tech"
          :key="tech.title"
        >
          <b class="d-block mb-2">{{ tech.title }}</b>
          <p
            class="bg-dark pl-2 pr-2 d-inline-block rounded mr-3 mb-2"
            v-for="stack in tech.stack"
            :key="stack.name"
            :class="['text-' + stack.variant]"
          >
            {{ stack.name }}
          </p>
        </div>
      </div>
      <div
        class="col-lg-12 mb-3"
        v-for="(info, idx) in project.info"
        :key="'info-' + idx"
      >
        <h1>{{ info.title }}</h1>
        <div
          v-for="(paragraph, jdx) in info.description"
          :key="'paragraph-' + jdx"
        >
          <p v-if="paragraph.type === 'text'">{{ paragraph.value }}</p>
          <div class="w-100 text-center d-block">
            <IImage
              :src="paragraph.value"
              v-if="paragraph.type === 'image'"
              img-style="max-width: 840px; width: 100%"
              :rounded="paragraph.rounded === true"
            />
          </div>
          <p
            class="text-center mt-3"
            v-if="
              paragraph.type === 'image' &&
              paragraph.alt &&
              paragraph.alt.length > 0
            "
          >
            {{ paragraph.alt }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IImage from "./IImage.vue";

export default {
  name: "ProjectCard",
  components: { IImage },
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>