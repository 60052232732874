<template>
  <div class="i-navbar container text-right">
    <router-link
      class="mr-3 in-link"
      v-for="route of $routes"
      :key="route.path"
      :to="route.path"
      :class="{ active: route.path === $route.path }"
      >{{ route.name.toUpperCase() }}
      <sup
        v-if="route.path === '/game-server'"
        style="line-height: 0px"
        class="text-primary"
        ><small class="font-weight-bold">WORTH TO READ</small></sup
      >
    </router-link>
  </div>
</template>

<script>
import router from "./../router";

export default {
  name: "Navbar",
  computed: {
    $routes() {
      return router;
    },
  },
};
</script>

<style>
.in-link {
  transition: color 200ms, border 100ms;
  font-weight: bold;
  outline: none !important;
  text-decoration: none !important;
  color: var(--black);
  display: inline-block;
}

@media (max-width: 768px) {
  .in-link {
    text-align: left;
    display: block !important;
  }
}
.in-link.active,
.in-link:hover,
.in-link:active,
.in-link:focus {
  color: var(--i-primary);
}

.in-link.active {
  border-bottom: 2px solid var(--i-primary);
}
</style>