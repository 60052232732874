<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="offset-lg-1 col-lg-3 text-center pb-5">
          <img
            src="https://avatars.githubusercontent.com/u/520683"
            class="i-avatar rounded-circle shadow"
          />
        </div>
        <div class="col-lg-7 offset-lg-1">
          <h1>Hello, I am Bryan Iribe.</h1>
          <p class="text-muted mb-4 d-block">FULL STACK SENIOR SOFTWARE ENGINEER</p>
          <p class="bg-warning text-dark p-2 pl-3 pr-3 rounded"><img src="/icons/graduation-cap-solid.svg" class="i-cap" /> Software Engineer bachelor’s degree completed at Instituto Tecnologico de Sonora (ITSON) in May 2023.</p>
          <SocialMediaBtn
            class="mr-2"
            v-for="s of $social"
            :icon="s.icon"
            :key="s.name"
            :href="s.path"
          />
          <p class="mt-3">
            I am a Senior Software Engineer. I work as a Full stack developer, I have worked in development area for more than +5 years. I am currently going for DevOps & cloud solutions. In my free time, I do exercise and play video games. I am a kitten lover. I love physics, economics, and political topics.
          </p>
          <div class="row">
            <div class="col-sm-6 mb-3">
              <p class="mt-3 text-danger">
                My personal taste is Vue.js and Golang! 
              </p>
            </div>
            <div class="col-sm-6 d-flex align-items-center mb-3">
            <img class="i-go" src="/images/vue.png" />
            <img class="i-go" src="/images/heart-balloon.png" />
            </div>
          </div>
          <a href="mailto:brayaniribe@protonmail.com" target="_blank">
            Need resume or personal details? Contact me through email.
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialMediaBtn from "./../components/SocialMediaBtn.vue";

const SocialMedia = [
  {
    icon: "linkedin.svg",
    name: "Linkedin",
    path: "https://www.linkedin.com/in/brayan-iribe-95b1b0227/",
  },
  {
    icon: "github.svg",
    name: "GitHub",
    path: "https://github.com/BrayanIribe",
  },
  {
    icon: "email.svg",
    name: "Email",
    path: "mailto:brayaniribe@protonmail.com",
  },
];

export default {
  components: {
    SocialMediaBtn,
  },
  computed: {
    $social() {
      return SocialMedia;
    },
  },
};
</script>

<style>
.i-avatar {
  max-width: 10rem;
}

.i-cap {
  width:1rem;
  height:1rem;
}

.i-go {
  width:3rem;
  height:3rem;
}
</style>