import AboutMe from './views/AboutMe.vue';
//import ReverseEngineering from './views/ReverseEngineering.vue';
import GameServer from './views/projects/GameServer.vue';
import TecPymeCloud from './views/projects/TecPymeCloud.vue';
import TecPymePos from './views/projects/TecPymePos.vue';

export default [
    { path: '/', name: 'About me', component: AboutMe, },
    { path: '/tpos', name: 'POS', component: TecPymePos, },
    { path: '/tcloud', name: 'Cloud', component: TecPymeCloud, },
    { path: '/game-server', name: 'Game server', component: GameServer, },
    //  { path: '/reverse-engineering', name: 'Reverse engineering', component: ReverseEngineering, },
];