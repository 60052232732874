import Vue from 'vue'
import VueRouter from 'vue-router';
import VueGtag from "vue-gtag";

import App from './App.vue'
import routes from './router';
const router = new VueRouter({ mode: 'history', base: __dirname, routes })

Vue.use(VueRouter);
Vue.use(VueGtag, {
  config: { id: "G-ER9EMVMKPZ" }
}, router);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
