<template>
  <div class="i-tpos">
    <div class="container">
      <ProjectCard :project="$project" />
    </div>
  </div>
</template>

<script>
import ProjectCard from "./../../components/ProjectCard.vue";

export default {
  components: { ProjectCard },
  computed: {
    $project() {
      return {
        landing_image: "/images/game-server-1.png",
        windows: true,
        name: "Game server solution",
        label: "REAL-TIME ONLINE FPS GAME SERVER",
        description: "",
        advice:
          "This project is confidential (hence why no name is providen). The screenshot shown is only Grafana displaying game server metrics, and is real. If the recruiter wants to know the project name, watch server metrics, actual gameplay footage, website, etc. Please ask during the interview and I will kindly answer.",
        tech: [
          {
            title: "BACKEND",
            stack: [
              { name: "Node.js", variant: "white" },
              { name: "Go", variant: "white" },
              { name: "Unity 3D", variant: "white" },
              { name: "C#", variant: "white" },
              { name: "C++", variant: "white" },
              { name: "TCP", variant: "white" },
              { name: "UDP", variant: "white" },
              { name: "MySQL", variant: "white" },
              { name: "Sequelize", variant: "white" },
              { name: "Gorm", variant: "white" },
              { name: "AWS/GCP/DigitalOcean/Vultr", variant: "white" },
              { name: "Linux", variant: "white" },
              { name: "CloudFlare", variant: "white" },
              { name: "DB Caching", variant: "white" },
              { name: "Horizontal scaling", variant: "white" },
              { name: "Prometheus & Grafana", variant: "white" },
            ],
          },
          {
            title: "EXTRAS",
            small: true,
            stack: [
              { name: "IPC", variant: "white" },
              { name: "AES", variant: "white" },
              { name: "RSA", variant: "white" },
              { name: "Docker", variant: "white" },
              { name: "Vagrant", variant: "white" },
              { name: "OSI layer +3", variant: "white" },
            ],
          },
        ],
        info: [
          {
            title: "THE PROBLEM",
            description: [
              {
                type: "text",
                value:
                  "A massive-played online game is about to die; is 2017, the publisher has announced the shutdown. Post-mortem, the game is not playable anymore. It requires the backend service to run, but also strong reverse engineering skills, given that game was virtualized and uses many encryption protocols and also OSI layer +3 knowledge is mandatory. Everyone has losed the hope, some of them attempted to recreate the game with no success. The publisher has disappeared and the game just goes to the can.",
              },
            ],
          },
          {
            title: "ATTEMPTING THE IMPOSSIBLE",
            description: [
              {
                type: "text",
                value:
                  "I wish I could place some references here, but I cannot! Is 2020, Bryan is bored during his leisures. He takes the game binary and starts reverse engineering it. He gets a Proof of concept (POC) of how emulation could save the game using Node.js, implementing a basic TCP server. Community puts the hopes up and supports the project, starts growing!",
              },
              {
                type: "text",
                value:
                  "First deployments were done with AWS, as long the project grew, I noticed some faults on the server architecture, where I did learn much about the field. So, I rebuilt the project from scratch again, hoping it to get better.",
              },
              {
                type: "image",
                value: "/images/game-server-2.png",
                alt: "Initial server architecture, everything runs on a single container.",
              },
              {
                type: "text",
                value:
                  "The project was performing okay, but player base started to grow and also the game is very competitive. Most players run the game at 60 FPS or higher rates, so high tick servers shall be a thing here. With this architecture, it was pretty susceptible to fail. If any service fails, it would cause a DOS on the server, I had to overthink it again.",
              },
              {
                type: "image",
                value: "/images/game-server-3.png",
                alt: "Today's server architecture.",
              },
              {
                type: "text",
                value:
                  "As we can see Unity is on the graph. This is because we're emulating the original game server and given the game is a 3D FPS, I had to perform raycasts to determine whether a player shoot did hit or not, etc. I picked Unity because it has server builds (without renderer, only CLI) and also the learning curve is quite fast. The game server is coded in C#. Each game server communicates with the master server through a custom-made IPC, which was developed by me and runs over TCP sockets. It is a bidirectional connection, master server sends players data, map to be spawned, game mode, etc. The game server sends players data at the end of the match, with the kills, deaths, etc.",
              },
              {
                type: "text",
                value:
                  "I am a C++ enthusiast and I love to use it on Linux specifically, hence why most servers are running on C++ currently. The master server is the only one running with Go, because it allows to handle database clusters without much complexity, compared to C++. This new architecture implements horizontal server scaling, which definitely helped to solve server performance issues and also reduce server costs blatantly.",
              },
            ],
          },
          {
            title: "HORIZONTAL SCALING",
            description: [
              {
                type: "image",
                value: "/images/game-server-4.png",
                alt: "Instead of increasing hardware specs, I am spawning more servers on demand!",
              },
              {
                type: "text",
                value:
                  "I consider myself as a very innovative person, who loves to have everything automated and working without any sort of issues. This is why I introduced horizontal server scaling. Most cloud providers allows you to spawn virtual servers on demand pretty quickly on any location of the world and that's what I am doing: spawn virtual servers depending the player's region and destroy them when not in-use anymore. This reduced blatantly the server costs, and also is very innovative.",
              },
              {
                type: "image",
                value: "/images/servers.gif",
                alt: "Game server locations, thanks to Vultr & DigitalOcean",
              },
            ],
          },
          {
            title: "AUTOMATING GAME SERVER DEPLOYMNETS",
            description: [
              {
                type: "image",
                alt: " ",
                value: "/images/game-server-6.png",
              },
              {
                type: "text",
                value: `A custom server was required to be coded who could spawn game server instances on either DigitalOcean or Vultr, so I coded my own manager on Go. It exposes an HTTP microservice which allows to spawn game servers on-demand. The master server communicates to it and this microservice runs on its own, stand-alone. It was engineered to perform its tasks and destroy server when it detects no more usage on them. So, how it detects? When a new server is spawned, it also attaches a manager client which also exposes and keeps listening for requests. The manager, on the microservice, exposes the amount of instances running. If the total Unity instances runnings is equal to zero (meaning, there are not games running, but server is yet living), destroy it. Servers are created and destroyed communicating with either the DigitalOcean/Vultr microservice.`,
              },
            ],
          },
          {
            title: "SOLUTION",
            description: [
              {
                type: "text",
                value: `This system, on its core, is Vanilla Node.js based. No transpiler, babel, etc. Just raw JS code running on Node.js. Everything was coded from scratch. I lately switched to Go, because is a more robust and allows concurrency and more control of traffic than Node.js. Each millisecond on this project worths, so everything must be running as fast as possible, hence why I placed DB Caching on Go, to stop running heavy SQL queries on the database. Given this is an online FPS game server, UDP is used to interpolate players. So both protocols run at the same time, TCP and UDP. The game uses TCP to perform important tasks, such as login or chats, while UDP is used for shoots and player ticks. I am currently working on it to get 120hz servers by reworking my own event system in there. The game uses AES and RSA to protect the "session keys", so a RSA keychain is generated on each player connection.`,
              },
              {
                type: "text",
                value: `This solution is only running on cloud instances, with Digital Ocean for master servers and DO + Vultr for game servers.`,
              },
              {
                type: "text",
                value: `C++ is being used mostly for game servers but also to add custom code to game, shellcodes writen in ASM are also a thing.`,
              },
            ],
          },
        ],
      };
    },
  },
};
</script>

<style>
</style>